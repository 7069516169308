import './App.scss';
import { useEffect, useState } from 'react';
import Text from './components/Text';
import Choice from './components/Choice';
import ProgressBar from './components/ProgressBar';
import Form from './components/Form.js';
import Number from './components/Number.js';
import SubmitPage from './components/SubmitPage';
import OrderList from './components/OrderList.js';
import Content from './components/Content.js';
import Gallery from './components/Gallery.js';

function App ({ theInsights, setShow, handleProgressSubmit, stepQuestion }) {
	// Set the data.
	// const data = theInsights.structure[1].props.steps;
	// Note; setting the data like this due to the change to the json.
	// We may be able to revert to the above if the old style is definitely being used.
	const data = theInsights.structure.filter((str) => {
		if (str.view === 'steps' || str.view === 'questions' || str.view === '') {
			return str.view;
		}
	})[0].props[stepQuestion];

	// Set the conts for the various options.
	const [questions, setQuestions] = useState([]);
	const [choicesSelected, setChoicesSelected] = useState({
		choices: [],
	});
	const [responses, setResponses] = useState([]);
	const [submit, setSubmit] = useState(false);
	const [start, setStart] = useState(false);
	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [text, setText] = useState('');
	const [progress, setProgress] = useState(0);
	const [number, setNumber] = useState('');
	const [list, setList] = useState(null);
	const [unordered, setUnordered] = useState(null);
	const [skipList, setSkipList] = useState([]);
	const [form, setForm] = useState({});
	// Set the current question number string
	let questionNumber = 'Question ' + [currentQuestion + 1];
	if (data[currentQuestion].title) {
		questionNumber = data[currentQuestion].title;
	} else if (data[currentQuestion].type.toLowerCase() === 'content') {
		questionNumber = 'Info ' + [currentQuestion + 1];
	}
	let errorSelector = 'q' + [currentQuestion + 1] + 'error';

	// Setting respoonses with empty properties as question and value as empty string
	useEffect(() => {
		setResponses(data.map((data) => ({ [`${data.title}`]: '' })));
	}, []);

	// to go to next question
	const progressToNextQuestion = () => {
		if (currentQuestion < data.length - 1) {
			setCurrentQuestion(currentQuestion + 1);
		}

		// enable submit button
		if (currentQuestion === data.length - 1) {
			setSubmit(true);
		}
		// progress bar
		setProgress(prevProgress => {
			const newProgress = prevProgress + progressBarLength;
			return newProgress > 100 ? 100 : newProgress;
		});
	};

	// Set the pseudo-vh var
	var vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', vh + 'px');
	// Reset on resize
	window.addEventListener('resize', bpresize, false);
	function bpresize () {
		// Update the pseudo-vh
		vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', vh + 'px');
	}

	const showError = () => {
		// Show the error / Move the p into the div
		if (document.querySelector('#' + errorSelector)) {
			document.querySelector('#' + errorSelector).append(document.querySelector('#' + errorSelector + '-content'));
		}
		// Scroll into view
		document.querySelector('.question__error').scrollIntoView({
			behavior: 'smooth',
		});
		// And focus on the first aria-invalid element
		if (document.querySelector('.question__question [aria-invalid="true"]')) {
			console.log(document.querySelector('.question__question [aria-invalid="true"]'));
			document.querySelector('.question__question [aria-invalid="true"]').focus();
		}
	};

	const hideError = () => {
		// Hide the error by removing the p
		if (document.querySelector('#' + errorSelector)) {
			document.querySelector('#' + errorSelector).parentNode.append(document.querySelector('#' + errorSelector + '-content'));
		}
	};

	// Email validation from https://stackoverflow.com/a/46181
	const validateEmail = (email) => {
		return email.match(
			// eslint-disable-next-line
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
	};

	// Next button
	const nextQuestion = () => {
		// Set the current type as a variable.
		let currentType = data[currentQuestion].type;

		// Switch based on lower case to avoid issues.
		switch (currentType.toLowerCase()) {
			// Numbers [range slider]
			case 'number':
				const numberValue = { [`${data[currentQuestion].title}`]: number };
				responses[currentQuestion] = numberValue;
				setResponses({ ...responses });
				break;

			// Paragraph text
			case 'paragraph':
				const paragraphValue = { [`${data[currentQuestion].title}`]: text };
				if (data[currentQuestion].required && text === '' || text.length > data[currentQuestion].max_characters) {
					// If not enough choices have been selected return false so it doesn't go to the next question
					showError();
					return false;
				} else {
					hideError();
				}
				responses[currentQuestion] = paragraphValue;
				setResponses({ ...responses });
				break;

			// Order [choices with drag-and-drop]
			case 'order':
				if (list) {
					const listValue = { [`${data[currentQuestion].title}`]: list };
					responses[currentQuestion] = listValue;
					setResponses({ ...responses });
					if (skipList[currentQuestion]) {
						delete skipList[currentQuestion];
					}
				}
				break;

			// Form with multiple inputs.
			case 'form':
				let hasErrors = false;
				const hideAllErrors = () => {
					document.querySelectorAll('.single-error, single-error--required, single-error--format').forEach(function (el, i) {
						el.classList.remove('single-error', 'single-error--required', 'single-error--format');
					});
				};
				// Hide any exisiting errors
				hideAllErrors();
				// Loop through the form fields...
				for (var i = data[currentQuestion].fields.length - 1; i >= 0; i--) {
					let currentID = data[currentQuestion].fields[i].label.toLowerCase().replace(/\s/g, '-').replace(/\W/g, '-');
					// Validate if any are required
					if (data[currentQuestion].fields[i].required) {
						if (!form[data[currentQuestion].fields[i].key]) {
							document.querySelector('#' + currentID).classList.add('single-error', 'single-error--required');
							hasErrors = true;
							document.querySelector('#' + currentID).setAttribute('aria-invalid', true);
							document.querySelector('#' + currentID).setAttribute('aria-describedby', currentID + 'error');
						} else {
							document.querySelector('#' + currentID).removeAttribute('aria-invalid');
							document.querySelector('#' + currentID).removeAttribute('aria-describedby');
						}
					}
					// Validate if it's an email address
					if (data[currentQuestion].fields[i].type === 'email') {
						let emailValue = form[data[currentQuestion].fields[i].key];
						if (emailValue && !validateEmail(emailValue)) {
							document.querySelector('#' + currentID).classList.add('single-error', 'single-error--format');
							hasErrors = true;
							document.querySelector('#' + currentID).setAttribute('aria-invalid', true);
							document.querySelector('#' + currentID).setAttribute('aria-describedby', currentID + 'error');
						} else {
							document.querySelector('#' + currentID).removeAttribute('aria-invalid');
							document.querySelector('#' + currentID).removeAttribute('aria-describedby');
						}
					}
				}

				// If there are any errors...
				if (hasErrors) {
					// Show the main error.
					showError();
					return false;
				} else {
					// ...or hide all errors
					hideError();
					hideAllErrors();
				}

				responses[currentQuestion] = form;
				setResponses({ ...responses });
				break;

			// Choices, either radio (max answer = 1), or checkboxes (max answer > 1)
			case 'choice':
				let min = 0;
				if (data[currentQuestion].min_answers) {
					min = data[currentQuestion].min_answers;
				}
				const choiceValue = { [`${data[currentQuestion].key}`]: choicesSelected.choices };
				if (choicesSelected.choices.length < min) {
					// If not enough choices have been selected return false so it doesn't go to the next question
					showError();
					return false;
				} else {
					hideError();
				}
				responses[currentQuestion] = choiceValue;
				setResponses({ ...responses });
				break;

			default:
				// Do nothing by default.
		}

		// Progress to the next question, call this after the data above so validation can be done
		progressToNextQuestion();

		// Reset the text, number and choices
		setText('');
		setNumber('');
		setChoicesSelected({
			choices: [],
		});

		// if next question is a form set responses to form
		if (data[(currentQuestion + 1)] && data[(currentQuestion + 1)].type === 'form') {
			setForm(responses[currentQuestion + 1]);
		}

		// focus on back button so you're at the start of the new screen
		document.querySelector('.survey__back button').focus();
	};

	// Previous button
	const prevQuestion = () => {
		// disabled={progress <= 0}
		if (progress <= 0 || currentQuestion <= 0) {
			setShow(false);
			// console.log('app');
			handleProgressSubmit('Welcome');
		}

		if (currentQuestion < data.length) {
			setCurrentQuestion(currentQuestion - 1);
		}

		// progress bar
		setProgress(prevProgress => {
			const newProgress = prevProgress - progressBarLength;
			return newProgress < 0 ? 0 : newProgress;
		});

		if (data[(currentQuestion - 1)]) {
			// [TODO] Make this a switch?
			// Set responses to text
			if (data[(currentQuestion - 1)].type === 'paragraph') {
				const Ans = responses[(currentQuestion - 1)];
				for (var paraKey in Ans) {
					setText(Ans[paraKey]);
				}
			}
			// Set responses to number
			if (data[(currentQuestion - 1)].type === 'number') {
				const Ans = responses[(currentQuestion - 1)];
				for (var numKey in Ans) {
					setNumber(Ans[numKey]);
				}
			}

			// Set responses to list/order
			if (data[(currentQuestion - 1)].type === 'order' && responses[currentQuestion]) {
				setList(Object.values(responses[(currentQuestion - 1)])[0]);
			}

			// Set responses to form
			if (data[(currentQuestion - 1)].type === 'form' && responses[currentQuestion]) {
				setForm(responses[currentQuestion - 1]);
				// console.log(responses[currentQuestion], 'cq');
			}
		}

		if (data[currentQuestion].type === 'choice' && choicesSelected) {
			const choiceValue = { [`${data[currentQuestion].key}`]: choicesSelected.choices };
			responses[currentQuestion] = choiceValue;
			setResponses({ ...responses });
		}
	};

	// Skip question
	const skipQuestion = () => {
		progressToNextQuestion();

		// Set the current type as a variable.
		let currentType = data[currentQuestion].type;

		// Switch based on lower case to avoid issues.
		switch (currentType.toLowerCase()) {
			// Numbers [range slider]
			case 'number':
				const numberValue = { [`${data[currentQuestion].title}`]: '' };
				responses[currentQuestion] = numberValue;
				setResponses({ ...responses });
				break;

			// Paragraph text
			case 'paragraph':
				const paragraphValue = { [`${data[currentQuestion].title}`]: '' };
				responses[currentQuestion] = paragraphValue;
				setResponses({ ...responses });
				break;

			// Choices, either radio (max answer = 1), or checkboxes (max answer > 1)
			case 'choice':
				const choiceValue = { [`${data[currentQuestion].key}`]: '' };
				responses[currentQuestion] = choiceValue;
				setResponses({ ...responses });
				break;

			default:
				// Do nothing by default.
		}

		if (currentType.toLowerCase() === 'order') {
			const value1 = { [`${data[currentQuestion].title}`]: '' };
			skipList[currentQuestion] = value1;
			setSkipList({ ...skipList });
		}
		if (currentType.toLowerCase() === 'form') {
			Object.keys(responses[currentQuestion]).forEach(key => responses[currentQuestion][key] = '');
		}
	};

	// list handler
	const listOrderChange = (value) => {
		setList(value);
	};

	// progress bar
	const progressIntialVal = 0;
	const progressBarLength = (100 / data.length);
	useEffect(() => {
		setProgress(progressIntialVal > 100 ? 100 : progressIntialVal < 0 ? 0 : progressIntialVal);
	}, [progressIntialVal]);

	useEffect(() => {
		handleProgressSubmit(questionNumber);
	}, [questionNumber]);

	// set initial values to responses from data
	useEffect(() => {
		// set initial list to order
		if (data[currentQuestion].type === 'order') {
			const ArrayList = data[currentQuestion].answers.map((item) => item.label);
			setUnordered(ArrayList);
		}
		// set initial properties with empty values to form
		if (data[currentQuestion].type === 'form') {
			const isNotEmpty = Boolean(Object.values(responses[currentQuestion]).join(''));
			if (!isNotEmpty) {
				data[currentQuestion].fields.forEach((fields, i) => {
					setForm(form => ({ ...form, [`${fields.label}`]: '' }));
				});
			}
		}
	}, [currentQuestion]);

	// Setting order questions
	useEffect(() => {
		if (responses[currentQuestion] && data[currentQuestion].type === 'order') {
			setList(unordered);
			if (Object.values(responses[(currentQuestion)])[0] != '') { // [TODO] should this be `!==` ?
				setList(Object.values(responses[(currentQuestion)])[0]);
			}
		}
	}, [unordered]);

	return (
		<div className="App">
			{ !submit ? (
				<div className="survey">
					<header className="survey__header">
						<div className="survey__back">
							<button className="plain" onClick={prevQuestion} >Back</button>
						</div>
						<div className="survey__name">{theInsights.structure[0].props.display_name}</div>
						<div className="survey__skip">
							<button className="plain" onClick={skipQuestion}>Skip</button>
						</div>
						<div className="survey__progress">
							<ProgressBar progress={progress} />
						</div>
					</header>

					{(() => {
						// Set the current type as a variable.
						let currentType = data[currentQuestion].type;

						// Switch based on lower case to avoid issues.
						switch (currentType.toLowerCase()) {
							// Numbers [range slider]
							case 'number':
								return (<Number data={data} currentQuestion={currentQuestion} responses={responses} setResponses={setResponses} number={number} setNumber={setNumber}/>);
								break;

							// Paragraph text
							case 'paragraph':
								if (responses[currentQuestion]) {
									return (<Text data={data} currentQuestion={currentQuestion} nextQuestion={nextQuestion} setText={setText} text={text} responses={responses}/>);
								}
								break;

							// content
							case 'content':
								if (responses[currentQuestion]) {
									return (<Content data={data} currentQuestion={currentQuestion} nextQuestion={nextQuestion} responses={responses}/>);
								}
								break;

							// content
							case 'gallery':
								if (responses[currentQuestion]) {
									return (<Gallery data={data} currentQuestion={currentQuestion} nextQuestion={nextQuestion} responses={responses}/>);
								}
								break;

							// Order [choices with drag-and-drop]
							case 'order':
								if (list) {
									return (<OrderList data={data} currentQuestion={currentQuestion} responses={responses} setResponses={setResponses} list={list} setList={setList} listOrderChange={listOrderChange} unordered={unordered}/>);
								}
								break;

							// Form with multiple inputs.
							case 'form':
								return (<Form data={data} currentQuestion={currentQuestion} responses={responses} setResponses={setResponses} form={form} setForm={setForm} />);
								break;

							// Choices, either radio (max answer = 1), or checkboxes (max answer > 1)
							case 'choice':
								return (<Choice data={data} currentQuestion={currentQuestion} responses={responses} choicesSelected={choicesSelected} setChoicesSelected={setChoicesSelected}/>);
								break;

							default:
								// Do nothing by default.
						}
					})()}
					<footer className="survey__footer">
						<button onClick={nextQuestion}>Next</button>
					</footer>
				</div>)
				: (
					<SubmitPage insights={theInsights} responses= {responses} skipList={skipList} setResponses={setResponses} setSubmit={setSubmit} handleProgressSubmit={handleProgressSubmit} currentQuestion={currentQuestion}/>
				)
			}
		</div>
	);
}

export default App;

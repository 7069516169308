import React, { useEffect } from 'react';
import { useState } from 'react';
import ThankYou from './ThankYou';
import { resetServerContext } from 'react-beautiful-dnd';

export default function SubmitPage ({ responses, insights, skipList, setResponses, setSubmit, handleProgressSubmit, currentQuestion }) {
	const [submitting, setSubmitting] = useState([]);
	// if they skip drag and drop ordered questions
	for (let key in skipList) {
		// console.log(key);
		responses[key] = skipList[key];
	}

	const handleSubmit = async (event) => {
		event.preventDefault();
		// If there's a submitting class stop any further calls.
		if (document.querySelector('.survey__footer button').classList.contains('submitting')) {
			return false;
		}
		setSubmitting('');
		let uncheckedRequiredSelector = '.survey__agreements input.required:not(:checked)';
		if (document.querySelector(uncheckedRequiredSelector)) {
			let checkID = document.querySelector(uncheckedRequiredSelector).getAttribute('id');
			document.querySelector('.survey__agreements').classList.add('submit-error');
			// Note: this assumes only 1 required option, if there are more validation may be annoying for non-sighted users.
			// But there are announcements of required, and this will just loop until complete.
			document.querySelector('#' + checkID).setAttribute('aria-invalid', true);
			document.querySelector('#' + checkID).setAttribute('aria-describedby', checkID + 'error');
			document.querySelector('#' + checkID).focus();
			return false;
		} else {
			document.querySelector('.survey__agreements').classList.remove('submit-error');
		}

		const responsesArray = Object.values(responses);
		const responsesObject = responsesArray.reduce(function (result, current) {
			return Object.assign(result, current);
		}, {});

		/*
			Submitting the data
			Using a try/catch, so it will:
			- try to connect to the API, and if it works jump to the thankyou page
			- if it doesn't it will fail and display an error message
			The button also gets a submitting class to (apprear to) be disabled.
		 */
		try {
			setSubmitting('Submitting');
			document.querySelector('.survey__footer button').classList.add('submitting');
			document.querySelector('.survey__footer button').classList.remove('error');
			let data = await insights.actions.data(responsesObject);
			if (data) {
				handleShowClick();
			} else {
				throw new TypeError('Failed to send');
			}
		} catch (e) {
			setSubmitting('There was an error submitting your response. Please try again.');
			document.querySelector('.survey__footer button').classList.remove('submitting');
			document.querySelector('.survey__footer button').classList.add('error');
		}
	};

	// handle agreements
	const handleAgreements = (event) => {
		if (event.target.checked) {
			agreement[event.target.name] = true;
			responses['agreements'] = agreement;
			setResponses({ ...responses });
			if (!document.querySelector('.survey__agreements input.required:not(:checked)')) {
				document.querySelector('.survey__agreements').classList.remove('submit-error');
				if (document.querySelector('.survey__agreements [aria-invalid]')) {
					document.querySelector('.survey__agreements [aria-invalid]').removeAttribute('aria-invalid');
					document.querySelector('.survey__agreements [aria-describedby]').removeAttribute('aria-describedby');
				}
			}
		} else {
			agreement[event.target.name] = false;
			responses['agreements'] = agreement;
			setResponses({ ...responses });
		}
	};

	// to show thank you page
	const [show, setShow] = useState(false);
	const handleShowClick = () => {
		setShow(true);
	};
	const [agreement, setAgreements] = useState({});

	// set agreements with empty string
	useEffect(() => {
		insights.structure.forEach((str) => {
			if (str.view === 'submit') {
				str.props.agreements.forEach((item) => {
					// console.log(item.value);
					setAgreements(agreement => ({ ...agreement, [`${item.key}`]: false }));
				});
			}
		});
		handleProgressSubmit('Submit');
	}, []);

	const prevQuestionSubmit = () => {
		setSubmit(false);
		const questionNumber = 'Question' + [currentQuestion + 1];
		handleProgressSubmit(questionNumber);
	};

	return (
		<>
			{!show && (
				<div className="survey survey__submit">
					{
						insights.structure.map((str) => {
							if (str.view === 'submit') {
								return (
									<div key={str.view}>
										<header className="survey__header">
											<div className="survey__back">
												<button className="plain" onClick={prevQuestionSubmit} >Back</button>
											</div>
										</header>
										<main className="survey__panel">
											<div className="survey__review">
												{str.props.logo_url != null ? <div className="survey__logo"><img src={str.props.logo_url} alt="" /></div> : null}
												<h1>{str.props.title}</h1>
												{/* <h2>Optional project subtitle</h2> */}
												<p className="line-breaks">{str.props.statement}</p>
											</div>
										</main>
										<footer className="survey__footer">
											<form onSubmit={(event) => handleSubmit(event)}>
												<div className='survey__agreements'>
													{
														str.props.agreements.map((agreement) => {
															let agreementID = agreement.key.toLowerCase().replace(/\s/g, '-').replace(/\W/g, '-');
															return (
																<div key={agreement.key}>
																	<input
																		type={(agreement.required) ? 'radio' : 'checkbox'}
																		id={agreementID}
																		name={agreement.key}
																		className={(agreement.required) ? 'required' : 'not-required'}
																		onClick={(event) => { handleAgreements(event); }}
																	/>
																	<label htmlFor={agreementID} >{agreement.label}&nbsp;{(agreement.required) ? <span className="agreements__required">(Required)</span> : ''}</label>
																	{(agreement.required) ? <div id={agreementID + 'error'} className="agreements__error">You must agree to this</div> : ''}
																</div>
															);
														})
													}
												</div>
												<div className="survey__policies">
													{str.props.privacy_link != null ? <a href={str.props.privacy_link} target="_blank" rel="noopener noreferrer">Privacy policy</a> : null}
													{str.props.terms_link != null ? <a href={str.props.terms_link} target="_blank" rel="noopener noreferrer">Terms & conditions</a> : null}
												</div>
												{(submitting) ? <div id='submit-error' role="alert" aria-atomic="true" className="submit__error"><span></span>{submitting}</div> : ''}
												<button type="submit">Submit</button>
											</form>
										</footer>
									</div>
								);
							}
						})
					}
				</div>
			)}
			{show && (<ThankYou insights={insights} handleProgressSubmit={handleProgressSubmit}/>)}
		</>
	);
}

import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { RiDragMove2Line } from 'react-icons/ri';

function OrderList ({ data, currentQuestion, responses, setResponses, list, setList, listOrderChange, unordered }) {
	const order = Object.values(responses[currentQuestion])[0];
	// console.log(order, "ordered");

	// if current respose empty set to list
	useEffect(() => {
		// console.log("current", Object.values(responses[currentQuestion]));
		if (responses[currentQuestion]) {
			if (Object.values(responses[currentQuestion])[0] === '') {
				setList(unordered);
			} else {
				setList(Object.values(responses[(currentQuestion)])[0]);
			}
		}
	}, [currentQuestion]);

	// List handler
	function handleOnDragEnd (result) {
		if (!result.destination) return;

		const items = Array.from(list);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		// setList(items);
		// console.log(items);
		listOrderChange(items);
	}
	// console.log(list, "list", Object.values(responses[currentQuestion]));

	return (
		<main key={[currentQuestion + 1]} className="sortable-list survey__panel">
			<p className="question__number">Step {[currentQuestion + 1]}/{data.length}</p>
			<div key={[currentQuestion + 1] + data[currentQuestion].title} className="question__question">
				<div className="question__label">{data[currentQuestion].title}</div>
				<div className="question__instructions">
					{(data[currentQuestion].instruction) ? data[currentQuestion].instruction : 'Drag the items into the order of preference, highest at the top'}
				</div>
				<DragDropContext key={[currentQuestion + 1]} onDragEnd={handleOnDragEnd}>
					<Droppable key={[currentQuestion + 1]} droppableId="list">
						{(provided) => (
							<ul key={[currentQuestion + 1] + data[currentQuestion].title} {...provided.droppableProps} ref={provided.innerRef}>
								{list.map((label, index) => {
									return (
										<Draggable key={[currentQuestion + 1] + label + index} draggableId={label} index={index}>
											{(provided) => (
												<li key={[currentQuestion + 1] + label + index} className="item" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
													<div key={[currentQuestion + 1] + label + index} className="details"><span>{label}</span></div>
													<div className="dragger"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><title>Drag</title><path d="M182.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-96 96c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L128 109.3V402.7L86.6 361.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l96 96c12.5 12.5 32.8 12.5 45.3 0l96-96c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 402.7V109.3l41.4 41.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-96-96z"/></svg></div>
												</li>
											)}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</ul>
						)}
					</Droppable>
				</DragDropContext>
				{ /* Note: this replicates the text the drag & drop library provides & is visible when there's focus inside the draggable list */ }
				<div className="question__access" tabindex="0">
					Press space bar to start a drag. When dragging you can use the arrow keys to move the item around and escape to cancel. Some screen readers may require you to be in focus mode or to use your pass through key
				</div>
			</div>
		</main>
	);
}

export default OrderList;

// JavaScript code using React hooks
import React, { useState, useEffect } from 'react';

const ProgressBar = ({ progress }) => {
	return (
		<div className="progress" style={{ width: `${progress}%` }} ></div>
	);
};

export default ProgressBar;

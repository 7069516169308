import React, { useEffect } from 'react';
import { useState } from 'react';

export default function Choice ({ data, currentQuestion, responses, setResponses, choicesSelected, setChoicesSelected }) {
	let min = 0;
	const questionEl = document.querySelector('.question__question');
	if (data[currentQuestion].min_answers) {
		min = data[currentQuestion].min_answers;
	}
	const max = data[currentQuestion].max_answers;
	let errorMessage = false;
	if (min || max > 1) {
		if (min && max) {
			if (min === max) {
				errorMessage = 'Choose ' + min + ' answer';
				if (min > 1) {
					errorMessage = errorMessage + 's';
				}
			} else {
				errorMessage = 'Choose between ' + min + ' and ' + max + ' options';
			}
		} else if (min) {
			errorMessage = 'Choose at least ' + min;
		} else {
			errorMessage = 'Choose up to ' + max;
		}
	}
	const errorSelector = 'q' + [currentQuestion + 1] + 'error';

	const [ansValue, setansValue] = useState(false);
	let answers = [];

	const handleChoiceChange = (evt) => {
		const { value, checked } = evt.target;
		const { choices } = choicesSelected;
		if (errorMessage) {
			// Move the p back into the wrapper by default
			document.querySelector('#' + errorSelector).parentNode.append(document.querySelector('#' + errorSelector + '-content'));
		}

		if (max === 1) {
			// On radios the current option is always the only one selected
			// Move the p back into the wrapper
			if (errorMessage) {
				document.querySelector('#' + errorSelector).parentNode.append(document.querySelector('#' + errorSelector + '-content'));
			}
			setChoicesSelected({
				choices: [value],
			});
		} else {
			// On checkboxes...
			if (checked) {
				// If it's greater than the max don't check
				// choices.length is 0 indexed
				if ((choices.length + 1) > max) {
					// Move the p into the error message
					if (errorMessage) {
						document.querySelector('#' + errorSelector).append(document.querySelector('#' + errorSelector + '-content'));
					}
					document.querySelector('.question__error').scrollIntoView({
						behavior: 'smooth',
					});
					return false;
				}

				// ...add to the array if selecting a new one
				setChoicesSelected({
					choices: [...choices, value],
				});
			} else {
				// Move the p back into the wrapper
				if (errorMessage) {
					document.querySelector('#' + errorSelector).parentNode.append(document.querySelector('#' + errorSelector + '-content'));
				}
				// ...or remove from the array if unchecking
				setChoicesSelected({
					choices: choices.filter(
						(e) => e !== value
					),
				});
			}
		}
	};

	useEffect(() => {
		// Work out if there are any saved options
		if (responses[currentQuestion]) {
			if (Object.values(responses[currentQuestion]) == '') {
				// If there's nothing saved set as a blank array
				setChoicesSelected({
					choices: [],
				});
			} else {
				// Otherwise set to the array saved in responses
				setChoicesSelected({
					choices: Object.values(responses[currentQuestion])[0],
				});
			}
		}
	}, [responses[currentQuestion]]);

	return (
		<>
			<main className="survey__panel question--checkbox">
				<p className="question__number">Step {[currentQuestion + 1]}/{data.length}</p>
				<fieldset>
					<legend>{data[currentQuestion].title}</legend>
					<div className="question__question">
						<h1 className='question__label' key={[currentQuestion + 1]}>
							{data[currentQuestion].title}&nbsp;{(min >= 1) ? <span className="question__required">(Required)</span> : ''}
						</h1>
						<div className="question__instructions">
							{(data[currentQuestion].instruction) ? data[currentQuestion].instruction : ''}
						</div>
						<div className={`${
							data[currentQuestion].answers.some(answers => answers.image_url !== null) ? 'question__input question__input--toggle toggle--image' : 'question__input question__input--toggle'
						}`}>
							<span className="min-max">{errorMessage}</span>
							{
								data[currentQuestion].answers.map((answers, index) => {
									return (
										<div key={[currentQuestion + 1] + answers.label} className="toggle">
											<input key={answers.label}
												type={max === 1 ? 'radio' : 'checkbox'}
												value={answers.value}
												id={'question' + [currentQuestion + 1] + '-' + index}
												name={'question' + [currentQuestion + 1]}
												className="toggle__input"
												onChange={(evt) => { handleChoiceChange(evt); }}
												checked={choicesSelected.choices.some((x) => x === answers.value) ? 'checked' : ''}
											/>
											<label
												htmlFor={'question' + [currentQuestion + 1] + '-' + index}
												className={`toggle__label ${choicesSelected.choices.some((x) => x === answers.value) ? 'checked-option' : ''}`}
											>
												<span>{answers.label}</span>
												{answers.image_url !== null ? <div><img src={answers.image_url} width="200" height="200" alt="" /></div> : null}
											</label>
										</div>
									);
								})
							}
						</div>
						{(errorMessage) ? <div className="question__error-wrapper"><div id={'q' + [currentQuestion + 1] + 'error'} className="question__error" role="alert" aria-atomic="true"></div><p id={'q' + [currentQuestion + 1] + 'error-content'} className="question__error-content">{errorMessage}</p></div> : ''}
					</div>
				</fieldset>
			</main>
		</>
	);
}

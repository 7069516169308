import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

export default function Content ({ currentQuestion, data, responses }) {
	// Note: alt text not being supplied yet.
	let imageDiv = ContentImage(data[currentQuestion].image, data[currentQuestion].crop);

	return (
		<main className="survey__panel">
			<p className="question__number">Step {[currentQuestion + 1]}/{data.length}</p>
			<div key={[currentQuestion + 1]} className="question__question question__question--content">
				<div className="panel-content">
					{imageDiv}
					<div className="panel-content__text markdown">
						<h1>{data[currentQuestion].title}</h1>
						<Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>{data[currentQuestion].body}</Markdown>
					</div>
				</div>
			</div>
		</main>
	);
}

function ContentImage (image, crop, alt) {
	if (!image) {
		return '';
	}

	let altText = '';
	if (alt) {
		altText = alt;
	}

	let img = <img src={image} className={`${(crop === true) ? 'cropped' : ''}`} alt={altText} />;
	return (
		<div className="panel-content__image">
			{img}
		</div>
	);
}

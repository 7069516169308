import React, { useState, useEffect } from 'react';

export default function Number ({ currentQuestion, data, setResponses, responses, number, setNumber }) {
	// Variables for the svg elements
	let circleCX = 160;
	let circleCY = 160;
	let circleRadius = 12;
	let strokeWidth = 12;
	let textScore = number;
	let arcNumberD = '';
	let arcBGD = '';
	let arcWidth = (250 - (circleRadius * 2) - (strokeWidth * 2));
	let arcCenter = 250;
	let arcCenterY = 300;
	let newXAmount;
	let newYAmount;

	// Values from the question or settings
	let min = data[currentQuestion].min;
	let max = data[currentQuestion].max;
	let initValue = data[currentQuestion].default;
	let init = false;

	// Set the number
	if (number) {
		initValue = number;
	}

	// If there are any responses, and this one is there / not null use that
	if (Object.values(responses).length) {
		if (!(Object.values(responses[currentQuestion])[0] === '')) {
			initValue = Object.values(responses[currentQuestion])[0];
			init = true;
		}
	}

	// Other variables for calculations
	let allCartesians = [];
	let allCartesiansX = [];
	let range = max - min;

	// Range slider javascript is modified from  https://codepen.io/HalEden/pen/BvXzzj
	// Polar to Cartesian works out the x/y positions based on the angle in degrees.
	function polarToCartesian (centerX, centerY, radius, angleInDegrees) {
		var angleInRadians = ((angleInDegrees - 180) * Math.PI / 180.0);

		return {
			x: centerX + (radius * Math.cos(angleInRadians)),
			y: centerY + (radius * Math.sin(angleInRadians)),
		};
	}

	// The allCartesians[X] arrays are filled based on the range so there's an x/y value for each step
	for (var i = 0; i < range; i++) {
		// Work out the angle for each step
		var rangeAngle = 180 / range * (i + 1);
		// And populate the arrays
		allCartesians[i] =  polarToCartesian(arcCenter, arcCenterY, arcWidth, rangeAngle);
		allCartesiansX[i] = polarToCartesian(arcCenter, arcCenterY, arcWidth, rangeAngle).x;
	}

	// Polar to Cartesian to Step aligns the circle to the range slider
	// rather than having it a percentage along the arc, which only aligns at 0/90/180 degress
	// This is only needed for the circle & the end of the number/black arc.
	function polarToCartesianToStep (xAmount) {
		// Loop through the X array and find out which one is the closest to the Step equivalent
		var closest = allCartesiansX.reduce(function (prev, curr, i) {
			if ((Math.abs(curr - xAmount) < Math.abs(prev - xAmount))) {
				return curr;
			} else {
				return prev;
			}
		});

		// Set the x/y values to the align to the arc & the range slider.
		for (var i = 0; i < allCartesians.length; i++) {
			if (closest === allCartesians[i].x) {
				newXAmount = allCartesians[i].x;
				newYAmount = allCartesians[i].y;
			}
		}
		return {
			x: newXAmount,
			y: newYAmount,
		};
	}

	// The slider function does most of the work based on the number.
	function slider (scoreVal) {
		// Calculate the x position based on the number of steps
		let startingX = (arcCenter - arcWidth);
		let newXAmount = ((arcWidth * 2) * ((scoreVal - min) / range));
		let newX = parseInt(startingX + newXAmount);

		function describeArc (x, y, radius, startAngle, endAngle, percentageEnd) {
			// Set the values for the start and end of the arcs
			let end = polarToCartesian(x, y, radius, endAngle);
			let start = polarToCartesian(x, y, radius, startAngle);
			// If it's the end of the number/black arc it needs to be step based.
			if (percentageEnd) {
				end = polarToCartesianToStep(newX);
			}
			// Create the svg path.
			let largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
			let d = [
				'M', end.x, end.y,
				'A', radius, radius, 0, largeArcFlag, 0, start.x, start.y,
			].join(' ');
			return d;
		}

		// Set the circle position, this uses the Step position.
		function moveCircle (x, y, radius) {
			let start = polarToCartesianToStep(newX);
			circleCX = start.x;
			circleCY = start.y;
			textScore = scoreVal;
		}

		// Update the arc positions.
		function alterArc () {
			arcNumberD = describeArc(arcCenter, arcCenterY, arcWidth, 0, ((scoreVal - min) / range * 180), true);
			arcBGD = describeArc(arcCenter, arcCenterY, arcWidth, 0, 180, false);
		}

		// Call the circle and arc functions.
		moveCircle(arcCenter, arcCenterY, arcWidth);
		alterArc();
	}

	// Initialise range slider, with either the default value or the user's selected option.
	if (!init) {
		slider(initValue);
		init = true;
	} else {
		slider(number);
	}

	// The onChange function.
	const numberChangeHandler = (e) => {
		// console.log(e.target.value, "target");
		slider(e.target.value);
		setNumber(e.target.value);
		// onChangeText(e.target.value);
	};

	useEffect(() => {
		setNumber(initValue);
	}, [responses[currentQuestion]]);

	// console.log("current", responses[currentQuestion]);
	// console.log(number, "number");

	return (
		<>
			<main className="survey__panel question--range">
				<p className="question__number">Step {[currentQuestion + 1]}/{data.length}</p>
				<fieldset>
					<legend>{data[currentQuestion].title}</legend>
					<div className="question__question">
						<h1 className="question__label">{data[currentQuestion].title}</h1>
						<div className="question__instructions">
							{(data[currentQuestion].instruction) ? data[currentQuestion].instruction : ''}
						</div>
						<div className="question__input">
							<div className="range-slider">
								<svg className="range-slider__svg" viewBox="0 0 500 320">
									<path id="arc-bg" fill="none" strokeLinecap="round" d={arcBGD} strokeWidth={strokeWidth} />
									<path id="arc-number" fill="none" strokeLinecap="round" d={arcNumberD} strokeWidth={strokeWidth} />
									<circle id="circle" cx={circleCX} cy={circleCY} r={circleRadius} strokeWidth={strokeWidth} />
									<rect id="plus1" x={circleCX} y={circleCY} width="15" height="3" transform="translate(-7.5 -1.5)" />
									<rect id="plus2" x={circleCX} y={circleCY} width="3" height="15" transform="translate(-1.5 -7.5)" />
								</svg>
								<div className="min"><span className="visually-hidden">Min: </span>{data[currentQuestion].min}</div>
								<div className="max"><span className="visually-hidden">Max: </span>{data[currentQuestion].max}</div>
								<div className="score">{textScore}</div>
								<div className="range-slider__input">
									<label htmlFor={'q' + [currentQuestion + 1]} className="visually-hidden">{data[currentQuestion].title}</label>
									<input
										id={'q' + [currentQuestion + 1]}
										type="range"
										// value={data[currentQuestion].default}
										value={initValue}
										// value = {number == ""? initValue : number }
										min={data[currentQuestion].min}
										max={data[currentQuestion].max}
										step ={data[currentQuestion].increment}
										onChange ={(e) => numberChangeHandler(e)}
										aria-label={data[currentQuestion].title}
									/>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</main>
		</>
	);
}

import React, { useEffect } from 'react';
import { useState } from 'react';

export default function Text ({ currentQuestion, data, setText, text, responses }) {
	const currentQ = document.querySelector('#q' + [currentQuestion + 1]);
	const errorSelector = 'q' + [currentQuestion + 1] + 'error';
	const maxChars = data[currentQuestion].max_characters;
	const changeHandler = (e) => {
		setText(e.target.value);
		if (e.target.value.length > maxChars) {
			// Move the p into the error message
			document.querySelector('#' + errorSelector).append(document.querySelector('#' + errorSelector + '-content'));
			// Add the aria-invalid & describedby attrs
			currentQ.setAttribute('aria-invalid', true);
			currentQ.setAttribute('aria-describedby', errorSelector);
		} else {
			// Move the p back into the wrapper
			document.querySelector('#' + errorSelector).parentNode.append(document.querySelector('#' + errorSelector + '-content'));
			// Remove the aria-invalid & describedby attrs
			currentQ.removeAttribute('aria-invalid');
			currentQ.removeAttribute('aria-describedby');
		}
	};

	// Keypress has additional error message checking to alert users, even though the maxlength handles the validation
	const handleKeyPress = (e) => {
		if (text.length === maxChars && e.target.value.length === maxChars) {
			// Move the p into the error message
			document.querySelector('#' + errorSelector).append(document.querySelector('#' + errorSelector + '-content'));
		} else {
			// Move the p back into the wrapper
			document.querySelector('#' + errorSelector).parentNode.append(document.querySelector('#' + errorSelector + '-content'));
		}
	};

	let errorMessage = false;
	if (data[currentQuestion].required || maxChars) {
		if (data[currentQuestion].required || maxChars) {
			errorMessage = 'An answer, of up to ' + maxChars + ' characters, is required.';
		} else if (maxChars) {
			errorMessage = 'Answers can be up to ' + maxChars + ' characters long.';
		} else {
			errorMessage = 'An answer is required.';
		}
	}

	useEffect(() => {
		if (!(Object.values(responses[currentQuestion])[0] === '')) {
			setText(Object.values(responses[currentQuestion])[0]);
		}
	}, [responses[currentQuestion]]);

	return (
		<>
			<main className="survey__panel">
				<p className="question__number">Step {[currentQuestion + 1]}/{data.length}</p>
				<div key={[currentQuestion + 1]} className="question__question question__question--textarea">
					<label htmlFor={'q' + [currentQuestion + 1]} className="question__label">
						{data[currentQuestion].title}&nbsp;{(data[currentQuestion].required) ? <span className="question__required">(Required)</span> : ''}
					</label>
					<div className="question__instructions">
						{(data[currentQuestion].instruction) ? data[currentQuestion].instruction : ''}
					</div>
					<div className="question__input textarea-wrapper" data-count={text.length} data-max={maxChars}>
						<textarea
							value={text}
							id={'q' + [currentQuestion + 1]}
							onChange={(e) => changeHandler(e)}
							onKeyPress={handleKeyPress}
							required={data[currentQuestion].required}
							maxLength={maxChars}
						/>
					</div>
					{(errorMessage) ? <div className="question__error-wrapper"><div id={'q' + [currentQuestion + 1] + 'error'} className="question__error" role="alert" aria-atomic="true"></div><p id={'q' + [currentQuestion + 1] + 'error-content'} className="question__error-content">{errorMessage}</p></div> : ''}
				</div>
			</main>
		</>
	);
}

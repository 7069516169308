import Slider from 'react-slick';

export default function Gallery ({ currentQuestion, data, responses }) {
	var settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};
	return (
		<>
			<main className='survey__panel'>
				<p className='question__number'>Step {[currentQuestion + 1]}/{data.length}</p>
				<div key={[currentQuestion + 1]} className="question__question question__question--gallery">
					<div className="panel-content">
						<div className="panel-content__text">
							<h1>{data[currentQuestion].title}</h1>
							<div className="line-breaks">{data[currentQuestion].description}</div>
						</div>
						<div className="panel-content__image">
							<div className="slider-wrapper">
								{
									data[currentQuestion].images.length > 0
										? (
											data[currentQuestion].images.length > 1
												? (
													<Slider {...settings}>
														{
															data[currentQuestion].images.map((image, index) => {
																return (
																	<figure className="gallery-image">
																		<img width="200" height="200" src={image.image} alt="" />
																		<figcaption>
																			<p>{image.caption}</p>
																		</figcaption>
																	</figure>
																);
															})
														}
													</Slider>
												) : (
													data[currentQuestion].images.map((image, index) => {
														return (
															<figure className="gallery-image">
																<img width="200" height="200" src={image.image} alt="" />
																<figcaption>
																	<p>{image.caption}</p>
																</figcaption>
															</figure>
														);
													})
												)
										) : ('')
								}
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
}

import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Kiosk from './Kiosk.js';

export default function ThankYou ({ insights, handleProgressSubmit }) {
	useEffect(() => {
		handleProgressSubmit('Thank you');
	}, []);

	const currentLocation = useLocation();

	return (
		<>
			<div>
				<Routes>
					<Route path="/kiosk" element={<Kiosk />} />
				</Routes>
			</div>
			<div className="survey">
				<main className="survey__panel survey__panel--full suvey__thanks">
					{insights.structure.map((str) => {
						if (str.view === 'thanks') {
							return (
								<div key={str.view} className="survey__review">
									<h1>{str.props.title}</h1>
									<p className="line-breaks">{str.props.statement}</p>
								</div>
							);
						}
					})}
				</main>
				{insights.structure.map((str) => {
					if (str.view === 'thanks') {
						if ( currentLocation.pathname === '/kiosk' ) {
							return (
								<div className="survey__footer">
									<a className="button" href="javascript:window.location.reload();">Restart Survey</a>
								</div>
							);
						} else if (str.props.more_url || !str.props.more_url == '' || str.props.more_statement || !str.props.more_statement == '') {
							return (
								<div className="survey__footer">
									{(str.props.more_statement && str.props.more_statement !== '') ? <p>{str.props.more_statement}</p> : null}
									{(str.props.more_url && str.props.more_url !== '') ? <a className="button" target="_blank" rel="noopener noreferrer" href={str.props.more_url}>Find out more</a> : null}
								</div>
							);
						}
					}
				})}
			</div>
		</>
	);
}

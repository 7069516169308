export const insights = {
    "structure": [
        {
            "view": "welcome",
            "props": {
                "logo_url": "https://cdn.icon-icons.com/icons2/2107/PNG/512/file_type_favicon_icon_130608.png",
                "title": "A Welcome Page Title",
                "subtitle": "A Welcome Page Subtitle",
                "description": "A Welcome Page Description",
                "client_name": "Aldi",
                "client_email": "privacy@aldi.com",
                "client_phone": "0123456",
                "client_privacy": "https://cavendishconsulting.com/privacy"
            }
        },
        {
            "view": "steps",
            "props": {
                "steps": [
                    {   
                        "step": "question",
                        "type": "paragraph",
                        "instruction": "Instructions: on paragraph?",
                        "title": "What do you think about the proposals????",
                        "key": "What do you think about the proposals?",
                        "max_characters": 100
                    },
                    {
                        "step": "question",
                        "type": "number",
                        "title": "What is your current age?",
                        "key": "What is your current age?",
                        "instruction": "Instructions: on number",
                        "default": 13,
                        "min": 13,
                        "max": 100,
                        "increment": 1
                    },
                    {
                        "step": "info",
                        "type": "content",
                        "title": "Store Details",
                        "image": "https://placehold.co/600x400",
                        "crop": true,
                        "body": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Curabitur id malesuada nisl.Maecenas justo eros, rhoncus sit amet dui ut, molestie auctor dolor.Integer porta neque nec condimentum vulputate.Aliquam malesuada, purus id sagittis venenatis, eros sem gravida nisi, non consequat est justo nec turpis.Mauris non dapibus urna, at lacinia lorem.Nullam faucibus commodo neque.Integer sagittis felis vel massa mollis, in posuere dui pretium.Etiam lorem eros, pretium et mollis et, commodo ac ex.Quisque mollis faucibus nibh, vitae rhoncus et",
                    },
                    {
                        "step": "info",
                        "type": "gallery",
                        "title": "Car Parking",
                        "description": "The site provides ample car parking space, have a look at the images below to see its facilities in more detail",
                        "images":[
                            {
                                "image": "https://placehold.co/600x400",
                                "caption": "Electric vehicle charging"
							},
                            {
                                "image": "https://placehold.co/600x400",
                                "caption": "Electric vehicle charging 2"
							}
                        ]
                    },
                    {
                        "step": "question",
                        "type": "choice",
                        "title": "Do you feel that a new Aldi food store would be a benefit to the area?",
                        "key": "Do you feel that a new Aldi food store would be a benefit to the area?",
                        "instruction": "Instructions: on choicesssss",
                        "max_answers": 1,
                        "answers": [
                            {
                                "image_url": null,
                                "label": "Yes",
                                "value": "Yes"
                            },
                            {
                                "image_url": null,
                                "label": "No",
                                "value": "No"
                            },
                            {
                                "image_url": null,
                                "label": "Unsure",
                                "value": "Unsure"
                            }
                        ]
                    },
                    {
                        "type": "choice",
                        "title": "What would you like to see on the ground floor commercial space?",
                        "key": "What would you like to see on the ground floor commercial space?",
                        "instruction": "Instructions: on choices",
                        "max_answers": 3,
                        "answers": [
                            {
                                "image_url": null,
                                "label": "Convenience Store",
                                "value": "Convenience Store",
                            },
                            {
                                "image_url": null,
                                "label": "Supermarket",
                                "value": "Supermarket",
                            },
                            {
                                "image_url": null,
                                "label": "Fashion Retailer",
                                "value": "Fashion Retailer",
                            },
                            {
                                "image_url": null,
                                "label": "Fast Food",
                                "value": "Fast Food",
                            },
                            {
                                "image_url": null,
                                "label": "Bars & Nightclubs",
                                "value": "Bars & Nightclubs",
                            },
                        ]
                    },
                    {
                        "type": "order",
                        "title": "Please rank the following in order of importance to you",
                        "key": "Please rank the following in order of importance to you",
                        "instruction": "Drag and drop in the order you'd like",
                        "answers": [
                            {
                                "label": "Parking",
                                "value": "Parking"
                            },
                            {
                                "label": "Library",
                                "value": "Library"
                            },
                            {
                                "label": "Parks",
                                "value": "Parks"
                            },
                            {
                                "label": "Local Shops",
                                "value": "Local Shops"
                            },
                            {
                                "label": "Schools",
                                "value": "Schools"
                            },
                        ]
                    },
		            {
		                "key": "Form",
		                "step": "question",
                        "instruction": "Instructions: on the form",
		                "type": "form",
		                "title": "Form",
		                "fields": [
			                {
			                    "key": "Text",
			                    "type": "text",
			                    "label": "Text",
			                    "personal": false
			                },
			                {
			                    "key": "Number",
			                    "type": "number",
			                    "label": "Number",
			                    "personal": false
			                },
			                {
			                    "key": "Date",
			                    "type": "date",
			                    "label": "Date",
			                    "personal": false
			                },
			                {
			                    "key": "Email",
			                    "type": "email",
			                    "label": "Email",
			                    "personal": false
			                },
			                {
			                    "key": "Phone",
			                    "type": "tel",
			                    "label": "Phone",
			                    "personal": false
			                }
		                ]
					},
                ]
            }
        },
	    {
	        "view": "submit",
	        "props": {
	            "title": "Submit your answers",
	            "statement": "Submit statement.\n\nLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
	            "logo_url": "https://cdn.icon-icons.com/icons2/2107/PNG/512/file_type_favicon_icon_130608.png",
	            "privacy_link": "https:\/\/cavendishconsulting.com\/privacy",
	            "terms_link": "https:\/\/cavendishconsulting.com\/terms",
	            "agreements": [
	            {
	                "label": "I agree to the privacy policy and confirm I'm over 13 years old.",
	                "key": "I agree to the privacy policy and confirm I'm over 13 years old.",
	                "required": true
	            },
	            {
	                "label": "Non required box",
	                "key": "Non required box",
	                "required": false
	            }
	            ]
	        }
	    },
        {
            "view": "thanks",
            "props": {
                "title": "A Thanks Page Title",
                "statement": "A Thanks Page Statement",
                "more_statement": "A Find Out More Statement",
                "more_url": "A Find Out More Link"
            }
        },
    ],
    "actions":{
        data(data){
            console.log("Submitting Data", data);
			return JSON.stringify(data);
        },
        interaction(type, label, target){
            console.log("Interaction", type, label, target)
        },
        progress(status){
            console.log("progress", status)
        },
    }
}
import React from 'react';

export default function ({ clientName, isCavendish = false }) {
	if (
		clientName === 'Cavendish' ||
		clientName === 'Cavendish Consulting' ||
		clientName === 'Cavendish Consulting Ltd' ||
		clientName === null
	) {
		isCavendish = true;
	}

	return (
		<div className='data_usage'>
			<h2>How we use your data</h2>
			{ isCavendish ? <p>Cavendish runs surveys/consultation activities.</p> : <p>Cavendish acts on behalf of the <b>{clientName}</b> (the project team) to run surveys/consultation activities.</p> }
			<p>By filling-in this online form you are agreeing that Cavendish can hold and process your Personal Data in relation to this exercise.</p>
			<p>Cavendish will share your Personal Data with the project team { isCavendish ? '' : 'companies' } for evaluation purposes only.</p>
			<p>Your Personal Data will not be used for any other purposes without your consent.</p>
			<p>Cavendish and the project team will use your Personal Data to:</p>
			<ul>
				<li>Send you updates about the project (where you provide us with your contact details); and </li>
				<li>Develop an overview report containing no Personal Data. Your comments will be anonymous and we will only identify you in these reports with your consent.</li>
			</ul>
			<p>If you provide us with your contact details, we may also contact you for more details regarding the comments you have made.</p>
		</div>
	);
}

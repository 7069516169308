import React from 'react';

export default function Cookie () {
	setTimeout(() => {
		const savedPrefs = localStorage.getItem('consent');
		if (!savedPrefs) {
			const cookieContainerBox = document.querySelector('.cookie-notice-container');
			cookieContainerBox.classList.remove('hide');
		}
	}, 1000);

	function AgreeHandler (e) {
		const cookieContainer = document.querySelector('.cookie-notice-container');
		cookieContainer.classList.add('hide');
		localStorage.setItem('consent', 'accepted');
	}

	return (
		<>
			<div className="cookie-notice-container hide">
				<div className="cn-container">
					<div className="cn-text-container">
						<p>We use functional cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.</p>
					</div>
					<div className="cn-buttons-container">
						<button onClick={(e) => AgreeHandler(e)}>Okay</button>
					</div>
				</div>
			</div>
		</>
	);
}

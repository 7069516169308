import React, { useState, useEffect } from 'react';

export default function Form ({ data, currentQuestion, responses, setResponses, form, setForm }) {
	// Email validation from https://stackoverflow.com/a/46181
	const validateEmail = (email) => {
		return email.match(
			// eslint-disable-next-line
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
	};

	const errorSelector = 'q' + [currentQuestion + 1] + 'error';

	const formHandler = (event) => {
		let value = event.target.value;
		let name = event.target.name;
		let type = event.target.type;
		let id = event.target.id;
		let req = event.target.required;
		let values = form;
		values[name] = event.target.value;
		setForm({ ...values });

		// Validate if any are required
		if (req) {
			if (!value) {
				document.querySelector('#' + id).classList.add('single-error', 'single-error--required');
				document.querySelector('#' + id).setAttribute('aria-invalid', true);
				document.querySelector('#' + id).setAttribute('aria-describedby', id + 'error');
			} else {
				document.querySelector('#' + id).classList.remove('single-error', 'single-error--required');
				document.querySelector('#' + id).removeAttribute('aria-invalid');
				document.querySelector('#' + id).removeAttribute('aria-describedby');
			}
		}

		// Hide the error that appears on pressing next if all the errors are cleared on typing
		if (!document.querySelector('.single-error')) {
			// Move the p back into the wrapper
			document.querySelector('#' + errorSelector).parentNode.append(document.querySelector('#' + errorSelector + '-content'));
		}
	};

	const blurValidation = (event) => {
		let value = event.target.value;
		let type = event.target.type;
		let id = event.target.id;
		let required = event.target.required;

		// Validate if it's an email address
		if (type === 'email' && (required === true || value !== '')) {
			if (!validateEmail(value)) {
				document.querySelector('#' + id).classList.add('single-error', 'single-error--format');
				document.querySelector('#' + id).setAttribute('aria-invalid', true);
				document.querySelector('#' + id).setAttribute('aria-describedby', id + 'error');
			} else {
				document.querySelector('#' + id).removeAttribute('aria-invalid');
				document.querySelector('#' + id).removeAttribute('aria-describedby');
				document.querySelector('#' + id).classList.remove('single-error--format');
				if (!document.querySelector('#' + id).classList.contains('single-error--required')) {
					document.querySelector('#' + id).classList.remove('single-error');
				}
			}
		}
	};

	useEffect(() => {
		// const value1 = {[`${data[currentQuestion].title}`] : form};
		responses[currentQuestion] = form;
		setResponses({ ...responses });
	}, [form]);

	// delete property that is getting added from next question function
	useEffect(() => {
		delete form[data[(currentQuestion)].title];
	}, [currentQuestion]);

	// Note; setting the data like this due to the change to the json.
	// We may be able to revert to the above if the old style is definitely not being used.
	let formFields = data[currentQuestion].answers || data[currentQuestion].fields;

	return (
		<>
			<main className="survey__panel question--form">
				<p className="question__number">Step {[currentQuestion + 1]}/{data.length}</p>
				<fieldset>
					<legend>{data[currentQuestion].title}</legend>
					<div className="question__question">
						<h1 className="question__label">{data[currentQuestion].title}</h1>
						<div className="question__instructions">
							{(data[currentQuestion].instruction) ? data[currentQuestion].instruction : ''}
						</div>
						<div className="question__error-wrapper">
							<div id={'q' + [currentQuestion + 1] + 'error'} className="question__error" role="alert" aria-atomic="true"></div>
							<p id={'q' + [currentQuestion + 1] + 'error-content'} className="question__error-content">Some fields need attention</p>
						</div>
						<div className="question__input">
							{
								formFields.map((fields, i) => {
									let formFieldID = fields.label.toLowerCase().replace(/\s/g, '-').replace(/\W/g, '-');
									let autoComplete = false;
									// Add autocomplete options
									if (fields.type === 'tel') {
										autoComplete = 'tel';
									} else if (fields.type === 'email') {
										autoComplete = 'email';
									}
									return (
										<div key={fields.label} className="single-text">
											<label htmlFor={formFieldID}>
												{fields.label}&nbsp;{(fields.required) ? <span className="question__required">(Required)</span> : ''}
											</label>
											<div className="single-text__error" id={formFieldID + 'error'}>
												{(fields.required) ? <div className="required">This field is required</div> : ''}
												{fields.type === 'email' ? <div className="format">Enter an email address in the correct format</div> : ''}
											</div>
											<input type={fields.type}
												id={formFieldID}
												name={fields.key}
												onChange={(event) => { formHandler(event); }}
												onBlur={(event) => { blurValidation(event); }}
												value={typeof responses[currentQuestion] !== 'undefined' ? responses[currentQuestion][fields.key] || '' : ''}
												inputMode={fields.type === 'tel' ? 'tel' : '' }
												autoComplete={autoComplete ? autoComplete : '' }
												required={fields.required}
											/>
										</div>
									);
								})
							}
						</div>
					</div>
				</fieldset>
			</main>
		</>
	);
}

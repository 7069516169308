import React from 'react';

export default function Kiosk ({ Kiosk, isKiosk }) {
	// Reload the page 3 seconds after the user's finished submitting.
	setTimeout(function () {
		window.location.reload();
	}, 3000);

	return (
		<></>
	);
}

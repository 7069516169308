import React from 'react';
import { Link } from 'react-router-dom';
import DataUse from './DataUse';

export default function ({ setFeedbackUse, clientName, clientEmail, clientPhone, clientPrivacy, isClientInfo = true, privacyText = '' }) { // [TODO] function need name
	const closefeedbackPop = () => {
		setFeedbackUse(false);
		// const IntroHeader = document.getElementsByClassName('intro_header')[0];
		// IntroHeader.style.display = 'block';
		const element = document.getElementsByClassName('modal')[0];
		element.classList.remove('modal-popup');

		// focus on the start button
		document.querySelector('.survey__cta button').focus();
	};

	// Email validation from https://stackoverflow.com/a/46181
	const validateEmail = (email) => {
		return email.match(
			// eslint-disable-next-line
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
	};

	// Hide the popup on escape press.
	document.addEventListener('keyup', keyupCheck);
	function keyupCheck (e) {
		var key = e.keyCode || e.which;
		if (key === 27) { // [Escape]
			if (document.querySelector('.popup')) {
				closefeedbackPop();
			}
		}
	}

	// Check the email's valid, set to null if not
	if (clientEmail && !validateEmail(clientEmail)) {
		clientEmail = null;
	}

	if (clientPrivacy) {
		clientPrivacy = clientPrivacy.replace('https://', '');
		clientPrivacy = clientPrivacy.replace('http://', '');
	}

	if (
		!clientEmail &&
		!clientPhone &&
		!clientPrivacy
	) {
		isClientInfo = false;
	} else {
		// [TODO] create this string here as the variances are quite complex.
		// Might need to change as it's using `dangerouslySetInnerHTML` to output.
		// But significantly cuts repetition.
		privacyText = ' To see our full Privacy Notice and find out how to exercise your data subject rights';
		if (clientPrivacy) {
			privacyText = privacyText + ', please visit <a href="' + clientPrivacy + '" target="_blank" rel="noopener noreferrer"><b class="popup__url">' + clientPrivacy + '</b></a>';
		}
		if (clientPhone) {
			privacyText = privacyText + ' or contact us by phone on <a href="tel:' + clientPhone + '"><b>' + clientPhone + '</b></a>';
		}
		if (clientEmail) {
			privacyText = privacyText + ' or email at <a href="mailto:' + clientEmail + '"><b class="popup__url"">' + clientEmail + '</b></a>';
		}
		privacyText = privacyText + '.';
	}

	return (
		<div className="popup">
			<button className="close_popup plain" onClick={closefeedbackPop}>Close</button>
			<div className="popup_content">
				<DataUse clientName={clientName}/>
				<div className='data_protection'>
					<h2>Data Protection</h2>
					<p>
						We process all Personal Data in accordance with the UK General Data Protection Regulation, the EU General Data Protection Regulation 2016/679 (together the “GDPR”) and the Data Protection Act 2018. Your Personal Data will not be transferred outside the UK or European Economic Area (the EU member states plus Norway, Iceland and Liechtenstein).
						<span dangerouslySetInnerHTML={{ __html: privacyText }} />
					</p>
					<p>You need to be 13 years old and over to lawfully provide consent for processing your personal data.</p>
				</div>
			</div>
		</div>
	);
}

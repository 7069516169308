import React, { useEffect } from 'react';
import { useState } from 'react';
import App from '../App';
import { insights } from '../data/Insights';
import FeedbackPopUp from './FeedbackPopUp';
import Cookie from './Cookie';

export default function IntroPage () {
	function shuffleArray (arr) {
		return arr.sort(() => Math.random() - 0.5);
	}

	// Set theInsights as the data.js by default.
	let theInsights = insights;
	// If defined in the index.html as window.insights then load from there
	if (window.insights) {
		theInsights = window.insights;
	}

	let stepQuestion;

	// Shuffle questions
	theInsights.structure.forEach((obj) => {
		if (obj.view === 'steps' || obj.view === 'questions') {
			stepQuestion = obj.view;
			if (stepQuestion) {
				obj.props[stepQuestion].forEach(
					(q) => {
						if ((q.type === 'choice' || q.type === 'order') && q.shuffle) {
							q.answers = shuffleArray(q.answers);
						}
					}
				);
			}
		}
	});

	// to show the questions
	const [show, setShow] = useState(false);
	const [feedbackUse, setFeedbackUse] = useState(false);

	// submit progress
	const handleProgressSubmit = (questionNumber) => {
		theInsights ? theInsights.actions.progress(questionNumber) : console.log('no insights data');
	};

	useEffect(() => {
		handleProgressSubmit('Welcome');
	}, []);

	const handleShowClick = () => {
		setShow(true);
	};

	// Add the interaction url click
	document.addEventListener('click', function (event) {
		// If it's a link that's been clicked
		if (event.target.matches('a')) {
			// Check some attributes
			var currentHost = window.location.hostname;
			var targetHost = event.target.hostname;
			var linkUrl = event.target.href;
			var linkText = event.target.innerText; // The text of the <a> by default.
			// console.log('link click!');
			// interaction('url click', linkUrl, linkText);
			insights.actions.interaction('url click', linkUrl, linkText);
		}
	});

	// Get the size of the image...
	// https://stackoverflow.com/a/11442850
	const getMeta = async (url) => {
		const img = new Image();
		img.src = url;
		await img.decode();
		return img;
	};
	// ... then set the size based on even areas
	if (insights.structure[0].props.logo_url) {
		getMeta(insights.structure[0].props.logo_url).then(img => {
			const maxArea = 90000; // The max area in pixels
			// Set the logo width, this grabs the image, works out the size and scales it down so they're all the same area.
			// Maths: image width, divided by the square root of the area (width * height), divided by the max area. Then rounded up to the nearest whole number
			// Example, a 600x400 image is 240,000 pixels, divided by the max area is 16, the square root of that is 4, and dividing the image width is 600 / 4 = 150
			document.documentElement.style.setProperty('--logo-width', Math.ceil(img.naturalWidth / Math.sqrt((img.naturalWidth * img.naturalHeight) / maxArea)) + 'px');
			document.documentElement.style.setProperty('--logo-aspect', Math.ceil(img.naturalWidth / img.naturalHeight) + '/1');
		});
	}

	// pop function to open feedback pop up
	const feedbackPop = () => {
		setFeedbackUse(true);
		if (setFeedbackUse) {
			const element = document.getElementsByClassName('modal')[0];
			element.classList.add('modal-popup');
			// const IntroHeader = document.getElementsByClassName('intro_header')[0];
			// IntroHeader.style.display = 'none';
		}
	};

	return (
		<>
			{!show && (
				<div className="survey">
					{theInsights.structure.map((str) => {
						if (str.view === 'welcome') {
							return (
								<main className="survey__panel survey__panel--full modal">
									<div className="survey__intro" key={str.view}>
										<div className="survey__logo">
											<img src={str.props.logo_url} alt=""/>
										</div>
										<h1>{str.props.title}</h1>
										<h2>{str.props.subtitle}</h2>
										<p className="line-breaks">{str.props.description}</p>
									</div>
									<div className="survey__cta">
										<button onClick={handleShowClick}>Start</button>
										{!feedbackUse && (<button className="open_popup plain" onClick={feedbackPop}>How do we use your data?</button>)} {feedbackUse && (<FeedbackPopUp setFeedbackUse={setFeedbackUse} clientName={str.props.client_name} clientEmail={str.props.client_email} clientPhone={str.props.client_phone} clientPrivacy={str.props.client_privacy} setShow={setShow}/>)}
									</div>
								</main>
							);
						}
					})}
				</div>
			)}
			{show && (<App theInsights={theInsights} stepQuestion={stepQuestion} setShow={setShow} handleProgressSubmit={handleProgressSubmit}/>)}
			<Cookie/>
		</>
	);
}
